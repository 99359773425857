// Selecting form elements and thank you message element
const emailForm = document.getElementById('emailForm') as HTMLFormElement;
const emailInput = document.getElementById('email') as HTMLInputElement;
const thankYouMessage = document.getElementById('thankYouMessage') as HTMLElement;

// Event listener for form submission
emailForm.addEventListener('submit', async (event) => {
  event.preventDefault(); // Prevent default form submission behavior

  const email = emailInput.value;

  try {
    // Send POST request to back end
    const response = await fetch('https://lv27ww834e.execute-api.eu-west-2.amazonaws.com/prod/waitlist', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    // Check if the response is ok (status 200-299)
    if (response.ok) {
      // Show thank you message
      thankYouMessage.style.display = 'block';
      // Optionally clear the form
      emailForm.reset();
    } else {
      alert('There was a problem with your submission.');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred. Please try again.');
  }
});
